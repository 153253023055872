<template>
  <div class="navigation">
    <div class="left_box">
      <div class="header" @click="ToSurvey()">
        <img src="../assets/logo.png">
      </div>
      <div :key="new Date().getTime()" class="nav_box">
        <div class="first_bt" @click="ClickFirstBt('subjects')">
          <a class="first_a">S &nbsp; 科目 </a>
        </div>
        <nav id="subjects_nav">
          <ul v-if="open.indexOf('subjects') > -1" class="first_ul">
            <li class="first_li" v-if="subjectsData.length < 1">无</li>
            <router-link v-for="(data, dataIndex) in subjectsData" :key="dataIndex" :to="'/subjects/' + data.id"
              class="router_link" :class="{first_li_choose: choose === data.id}">
              <li class="first_li" @click="navigate" @keypress.enter="navigate">
                {{ data.title }}
              </li>
            </router-link>
          </ul>
        </nav>
        <div class="first_bt" @click="ClickFirstBt('tasks')">
          <a class="first_a">T &nbsp; 任务 </a>
        </div>
        <nav id="tasks_nav">
          <ul v-if="open.indexOf('tasks') > -1" class="first_ul">
            <router-link v-for="(data, dataIndex) in tasksData" :key="dataIndex" :to="'/tasks/' + data.id"
              class="router_link" :class="{first_li_choose: choose === data.id}">
              <li class="first_li" @click="navigate" @keypress.enter="navigate">
                {{ data.title }}
              </li>
            </router-link>
          </ul>
        </nav>
        <div class="first_bt" @click="ClickFirstBt('history')">
          <a class="first_a">H &nbsp; 历史 </a>
        </div>
        <nav id="history_nav">
          <ul v-if="open.indexOf('history') > -1" class="first_ul">
            <router-link v-for="(data, dataIndex) in historyData" :key="dataIndex" :to="'/history/' + data.path + '/1'"
              class="router_link" :class="{first_li_choose: choose === data.id}">
              <li class="first_li" @click="navigate" @keypress.enter="navigate">
                {{ data.title }}
              </li>
            </router-link>
          </ul>
        </nav>
      </div>
      <div class="footer">
        <div class="settings" @click="ToSetting">设置</div>
        <div class="addTask" />
        <div class="logout" @click="Logout">退出</div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "store";

export default {
  name: "NavigationItem",
  props: {
    subjectsData: {
      type: Array,
      default: null,
    },
    tasksData: {
      type: Array,
      default: null,
    },
    historyData: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      open: [],
      choose: "",
    };
  },
  emits: {
    click: null
  },
  methods: {
    ClickFirstBt: function (button) {
      const index = this.open.indexOf(button);
      if (index > -1) {
        this.open.splice(index, 1);
        // document.getElementById("subjects_nav").style.height = "0";
      } else {
        this.open.push(button);
      }
    },
    ClickSecondBt: function (button) {
      console.log(button);
      this.choose = button;
    },
    Logout: function () {
      store.remove("HomeworkUpload_login_token");
      let redirect = decodeURIComponent(this.$route.query.redirect || "/login");
      alert("退出登录");
      this.$router
        .push({
          path: redirect,
        })
        .catch(() => { });
    },
    ToSurvey: function () {
      if (this.$route.path !== "/survey") {
        this.$router.push({ name: "网站概况" });
      }
    },
    ToSetting: function () {
      if (this.$route.path !== "/setting") {
        this.$router.push({ name: "网站设置" });
      }
    }
  },
};
</script>

<style scoped>
.navigation {
  height: 100%;
}

.left_box {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
}

.header {
  height: 10%;
  width: 100%;
}

.header:hover {
  cursor: pointer;
}

.header img {
  height: 100%;
}

.nav_box {
  height: 75%;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  overflow: auto;
  user-select: none;
}

.footer {
  height: 15%;
  width: 100%;
  background-color: yellow;
}

.first_bt {
  width: 100%;
  display: block;
  box-sizing: border-box;
  padding-top: 2vh;
  padding-bottom: 2vh;
  padding-left: 3vh;
  cursor: pointer;
  text-align: left;
}

.first_bt:hover a {
  color: rgb(10, 189, 254);
}

.first_a {
  width: 90%;
}

/*#subjects_nav{*/
/*  transition: all 100s linear;*/
/*}*/
/*.subjects_nav_tr-enter-active{*/
/*  transition: height 1s;*/
/*}*/
/*.subjects_nav_tr-enter{*/
/*  height: 0;*/
/*}*/
.first_ul {
  list-style-type: none;
  text-align: center;
  padding-left: 0;
  box-sizing: border-box;
  margin: 0;
  background-color: #F8F8FF;
}

.first_li {
  display: block;
  margin: 0;
  padding: 1vh;
  cursor: pointer;
}

.first_li:hover {
  color: rgb(10, 189, 254);
}

.first_li_choose {
  background-color: rgb(10, 189, 254);
}

.first_li_choose a {
  color: white;
}

.router_link {
  font-size: 15px;
  text-decoration: none;
  color: black;
}

.nav_router_link a {
  text-decoration: none;
}

span {
  float: right;
  margin: 0 10px;
  white-space: pre;
}

a {
  font-size: 20px;
  /* text-decoration: none; */
  color: black;
}

.settings {
  height: 35%;
  width: 100%;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  background-color: orange;
}

.settings:hover {
  color: white;
  cursor: pointer;
}

.addTask {
  height: 35%;
  width: 100%;
  background-color: white;
}

.logout {
  height: 30%;
  width: 100%;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  background-color: orange;
}

.logout:hover {
  color: white;
  cursor: pointer;
}
</style>
