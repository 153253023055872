/*
 validation 1.x
 */
var validation = {
  isEmpty: isEmpty
}

function isEmpty(str) {
  str = str || ''
  if (/^\s*$/.test(str)) {
    return true
  } else {
    return false
  }
}

export default validation
