<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}
.blue_border {
  border: 1px solid #99d3f5;
  color: #1e88c7;
  font-size: 2vmin;
  margin-top: 2vh;
  border-radius: 50vmin;
  height: 5vh;
  width: 8vw;
  box-sizing: border-box;
  outline-color: #99d3f5;
}
</style>
