<template>
  <div class="gauge" />
</template>

<script>
export default {
  name: "GaugeItem",
  props: {
    gaugeData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      MyChart: {},
    };
  },
  watch: {
    gaugeData: {
      handler() {
        this.MyChart.setOption({
          series: [
            {
              data: [this.gaugeData.EchartsData],
            },
          ],
        });
      },
      deep: true,
    },
  },
  created() {},
  mounted() {
    this.InitEcharts();
    const that = this;
    window.addEventListener("resize", function () {
      that.MyChart.resize();
    });
  },
  methods: {
    InitEcharts() {
      let myChart = this.$echarts.init(
        document.getElementById(this.gaugeData.GaugeID)
      );
      this.MyChart = myChart;
      let option = {
        series: [
          {
            type: "gauge",
            center: ["50%", "60%"],
            startAngle: 200,
            endAngle: -20,
            min: 0,
            max: 100,
            splitNumber: 10,
            itemStyle: {
              color: "#B7A8E4",//"#2085BF",
            },
            progress: {
              show: true,
              width: 30,
            },
            pointer: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                width: 30,
              },
            },
            axisTick: {
              distance: -45,
              splitNumber: 5,
              lineStyle: {
                width: 2,
                color: "#999",
              },
            },
            splitLine: {
              distance: -52,
              length: 14,
              lineStyle: {
                width: 3,
                color: "#999",
              },
            },
            axisLabel: {
              distance: -20,
              color: "#999",
              fontSize: 20,
            },
            anchor: {
              show: false,
            },
            title: {
              show: false,
            },
            detail: {
              valueAnimation: true,
              width: "60%",
              lineHeight: 40,
              borderRadius: 8,
              offsetCenter: [0, "-15%"],
              fontSize: 30,
              fontWeight: "bolder",
              color: "inherit",
            },
            data: [this.gaugeData.EchartsData],
          },
        ],
      };
      myChart.setOption(option);
    },
  },
};
</script>

<style scoped>
.gauge {
  width: 100%;
  height: 100%;
}
</style>
