<template>
  <div class="info">
    <Navigation
      class="navigation"
      :subjects-data="SubjectsData"
      :tasks-data="TasksData"
      :history-data="HistoryData"
    />
    <router-view class="router" />
  </div>
</template>

<script>
import Navigation from './Navigation'

export default {
  name: 'InfoItem',
  components: {
    Navigation
  },
  // provide () {
  //   return {
  //     reload: this.reload
  //   }
  // },
  data () {
    return {
      SubjectsData: [],
      TasksData: [],
      HistoryData: [
        {
          title: '科目',
          path: 'subjects'
        },
        {
          title: '任务',
          path: 'tasks'
        }
      ],
      isRouterAlive: true
    }
  },
  created () {
    this.GetSubjects()
    this.GetTasks()
  },
  mounted () {
  },
  methods: {
    // reload () {
    //   this.isRouterAlive = false
    //   this.$nextTick(function () {
    //     this.isRouterAlive = true
    //   })
    // },
    GetSubjects: function () {
      // const params = new URLSearchParams()
      // params.append('username', formData.username)
      // params.append('password', formData.password)
      this.axios.get('subject', {params: {state: 1}})
        .then((response) => {
          for (const data in response.data) {
            this.SubjectsData.push({
              id: response.data[data].id,
              title: response.data[data].subjectName
            })
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    GetTasks: function () {
      // const params = new URLSearchParams()
      // params.append('username', formData.username)
      // params.append('password', formData.password)
      const that = this
      this.axios.get('task', {params: {state: 1}})
        .then(function (response) {
          for (const data in response.data) {
            that.TasksData.push({
              id: response.data[data].id,
              title: response.data[data].taskName
            })
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    GetHistory: function () {
      const that = this
      this.axios.get('active/task')
        .then(function (response) {
          for (const data in response.data) {
            that.TasksData.push({
              id: response.data[data].id,
              title: response.data[data].taskName
            })
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>

<style scoped>
.info{
  display: flex;
  height: 100%;
}
.navigation{
  float: left;
  flex: 1;
  height: 100%;
  box-sizing: border-box;
  margin: 0;
  background: rgb(251, 251, 251);
  text-align: center;
}
.router{
  float: right;
  flex: 9;
  height: 100%;
}
</style>
