<template>
  <div class="login">
    <div class="head">班宝后台登录</div>
    <div class="center">
      <div class="login_div">
        <div class="input">
          <input
            v-model.trim="loginForm.username"
            type="text"
            placeholder="用户名"
            onfocus="this.placeholder=''"
            onblur="this.placeholder='用户名'"
            maxlength="20"
            @keyup.enter="FocusPassword()"
          />
        </div>
        <div class="input">
          <input
            v-model.trim="loginForm.password"
            type="password"
            placeholder="密码"
            onfocus="this.placeholder=''"
            onblur="this.placeholder='密码'"
            maxlength="25"
            ref="password"
            @keyup.enter="Login(loginForm)"
          />
        </div>
        <div id="login">
          <a class="login_bt"
            >登录
            <input
              style="cursor: pointer"
              type="button"
              @click="Login(loginForm)"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import validation from "../assets/script/validation";
// import sha256 from 'js-sha256'

export default {
  name: "LoginItem",
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    Validate: function (formData) {
      if (validation.isEmpty(formData.username)) {
        alert("请输入姓名");
        return false;
      } else if (validation.isEmpty(formData.password)) {
        alert("请输入密码");
        return false;
      } else {
        return true;
      }
    },
    Login: function (formData) {
      if (this.Validate(formData)) {
        const params = new URLSearchParams();
        params.append("username", formData.username);
        // params.append('password', sha256.sha256(formData.password))
        params.append("password", formData.password);
        const that = this;
        this.axios
          .post("account/login", params, { timeout: 30000 })
          .then(function (response) {
            if (response.data.username != null) {
              console.log(response.data);
              that.Store(formData);
            } else if (response.data === "Bad credentials") {
              alert("账号或密码错误");
            }
          })
          .catch(function (error) {
            console.log(error);
            alert("登录失败")
          });
      }
    },
    Store: function (formData) {
      const expiration = new Date().getTime() + 1800000;
      this.store.set("HomeworkUpload_login_token", formData, expiration);
      let redirect = decodeURIComponent(this.$route.query.redirect || "/info");
      alert("登录成功");
      this.$router
        .push({
          path: redirect,
        })
        .catch(() => {});
    },
    FocusPassword: function() {
      this.$refs.password.focus()
    }
  },
};
</script>

<style scoped>
.login {
  height: 100%;
  width: 100%;
  background-image: url("https://tc.0bug.cn/0bug.cn/background.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  text-align: center;
}
.head {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10%;
  font-size: 4vh;
  font-weight: bold;
  background-color: rgba(255, 255, 224, 0.1);
}
.center {
  height: 90%;
  width: 100%;
}
.input {
  padding-bottom: 2vh;
}
.input input {
  font-size: 2vmin;
  border: 1px solid #99d3f5;
  border-radius: 50vmin;
  height: 4vh;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline-color: #99d3f5;
}
.login_div {
  width: 100%;
  display: block;
  box-sizing: border-box;
  padding-top: 2vh;
  padding-bottom: 2vh;
}
.login_bt {
  position: relative;
  background: #d0eeff;
  border: 1px solid #99d3f5;
  overflow: hidden;
  color: #1e88c7;
  text-decoration: none;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  width: 8vw;
  height: 5vh;
  font-size: 2vmin;
  left: 46%;
  border-radius: 50vmin;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.login_bt:hover {
  cursor: pointer;
}
.login_bt input {
  position: absolute;
  font-size: 10vmax;
  right: -1vw;
  top: -1vw;
  width: 10vw;
  height: 7vh;
  opacity: 0;
}
.login_bt:hover {
  background: #aadffd;
  border-color: #78c3f3;
  color: #004974;
  text-decoration: none;
}
</style>
