<template>
  <div class="subject">
    <header>{{ SubjectName }}</header>
    <div class="MultipleSelectBox">
      <MultipleSelect v-if="MultipleSelectData.length !== 0" class="MultipleSelect"
        :multiple-select-data="MultipleSelectData" @select-change="SelectChange" />
    </div>
    <div class="TaskBox">
      <Tasks :taskId="SelectID"></Tasks>
    </div>
  </div>
</template>

<script>
import MultipleSelect from "./publiccomponents/MultipleSelect";
import Tasks from "./Tasks"
export default {
  name: "SubjectsItem",
  components: {
    MultipleSelect,
    Tasks,
  },
  data() {
    return {
      SubjectName: "",
      MultipleSelectData: [],
      SelectID: "",
    };
  },
  watch: {
    $route() {
      if (this.$route.params.subjectID != undefined) {
        this.GetTasksBySubject();
        this.GetSubject();
      }
    },
  },
  created() { },
  mounted() {
    this.GetSubject();
    this.GetTasksBySubject();
  },
  methods: {
    GetSubject: function() {
      this.axios
        .get("subject/" + this.$route.params.subjectID)
        .then((response) => {
          this.SubjectName = response.data.subjectName;
        })
        .catch(function () {
          alert("获取信息失败")
        })
    },
    GetTasksBySubject: function () {
      this.axios
        .get("subject/task/" + this.$route.params.subjectID)
        .then((response) => {
          if (response.data.length === 0) {
            this.MultipleSelectData = [];
          } else {
            response.data.map((data) => {
              this.MultipleSelectData.push({
                id: data.id,
                title: data.taskName,
              });
            });
            this.SelectID = this.MultipleSelectData[0].id;
          }
        })
        .catch(function () {
          alert("获取信息失败")
        });
    },
    SelectChange: function (value) {
      this.SelectID = Number(value) + 1;
    }
  },
};
</script>

<style scoped>
.subject {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
}

header {
  flex: 1;
  width: 100%;
  height: 2%;
  float: left;
  text-align: center;
  font-size: 20px;
}

.MultipleSelectBox {
  flex: 1;
  float: left;
  width: 10%;
  height: 3%;
}

.TaskBox {
  width: 100%;
  flex: 10;
}
</style>
