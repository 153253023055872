// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import store from 'store'
import * as echarts from 'echarts'

const app = createApp(App).use(router).use(router)
app.config.globalProperties.store = store
app.config.globalProperties.axios = axios
app.config.globalProperties.$echarts = echarts
axios.defaults.baseURL = window.apiURL.apiURL
// axios.defaults.baseURL = 'http://127.0.0.1:6060'
// axios.defaults.baseURL = 'https://api.0bug.cn'
// axios.defaults.baseURL = 'http://127.0.0.1:4523/mock/452788'


app.mount('#app')
