import { createRouter, createWebHashHistory } from 'vue-router'
import Upload from '../components/Upload'
import Login from '../components/Login'
import Error from '../components/Error'
import { getLogin } from '../assets/script/local.storage'
import Info from '../components/Info'
import Tasks from '../components/Tasks'
import Subjects from '../components/Subjects'
import Survey from '../components/Survey'
import Setting from '@/components/Setting'


const routes = [
  {
    path: '/',
    name: '班宝作业上传',
    component: Upload
  },
  {
    path: '/login',
    name: '登录',
    component: Login
  },
  {
    path: '/info',
    name: '管理面板',
    component: Info,
    redirect: '/survey',
    meta: {
      login: true
    },
    children: [
      {
        path: '/survey',
        name: '网站概况',
        component: Survey,
        meta: {
          login: true
        }
      },
      {
        path: '/subjects/:subjectID',
        name: '科目',
        component: Subjects,
        meta: {
          login: true
        }
      },
      {
        path: '/tasks/:taskID',
        name: '任务',
        component: Tasks,
        meta: {
          login: true
        }
      },
      {
        path: '/history/subjects/:subjectID',
        name: '历史科目',
        component: Subjects,
        meta: {
          login: true
        }
      },
      {
        path: '/history/tasks/:taskID',
        name: '历史任务',
        component: Tasks,
        meta: {
          login: true
        }
      },
      {
        path: '/setting',
        name: '网站设置',
        component: Setting,
        meta: {
          login: true
        }
      }
    ]
  },
  {
    path: '/:pathMatch(.*)',
    name: 'error',
    component: Error
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router

router.beforeEach((to, from, next) => {
  let title = to.matched[0].name
  if (to.meta.login) { // 判断该路由是否需要登录权限
    console.log(getLogin())
    if (getLogin().username && getLogin().password) { // 判断本地是否存在token
      if (to.matched[1]) {
        title += '-' + to.matched[1].name
      }
      document.title = title
      next()
    } else {
      // 未登录,跳转到登陆页面
      document.title = '登录'
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    }
  } else {
    if (to.matched[1]) {
      title = title + to.matched[1].name
    }
    document.title = title
    next()
  }
})
