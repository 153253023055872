<template>
  <select
    v-model="SelectID"
    class="Multiple_Select"
    @change="selectClass($event)"
  >
    <option
      v-for="(data, dataIndex) in multipleSelectData"
      :key="dataIndex"
      :value="dataIndex"
    >
      {{ data.title }}
    </option>
  </select>
</template>

<script>
export default {
  name: "MultipleSelect",
  emits: ['selectChange'],
  props: {
    multipleSelectData: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      SelectID: "",
    };
  },
  watch: {
    multipleSelectData: {
      handler() {
        this.SelectID = this.multipleSelectData[0].id - 1;
      },
      deep: true,
    },
  },
  created() {
    this.SelectID = this.multipleSelectData[0].id - 1;
  },
  methods: {
    selectClass: function (event) {
      this.$emit("selectChange", event.target.value);
    },
  },
};
</script>

<style scoped>
.Multiple_Select {
  width: 100%;
  height: 100%;
}
</style>
