<template>
  <div>error</div>
</template>

<script>
export default {
  name: 'ErrorItem'
}
</script>

<style scoped>

</style>
