import store from 'store'
import expirePlugin from 'store/plugins/expire'
store.addPlugin(expirePlugin)

export { store }

// 登录的信息
export const getLogin = function () {
  const token = store.get('HomeworkUpload_login_token')
  return token || ''
}
