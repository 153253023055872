<template>
  <div id="survey">
    <div class="title">网站概况</div>
    <div class="monitor">
      <Gauge id="cpu" :gauge-data="CPUData" />
      <Gauge id="memory" :gauge-data="MemoryData" />
    </div>
  </div>
</template>

<script>
import Gauge from "./publiccomponents/Gauge";

export default {
  name: "SurveyItem",
  components: {
    Gauge,
  },
  data() {
    return {
      CPUData: {
        EchartsData: {
          value: 0,
          MUse: 0,
          detail: {
            formatter: "{value} %\n\n{MUse}G/G".toString(),
          },
          itemStyle: {
              color: "#87B4FA",
          },
        },
        GaugeID: "cpu",
      },
      MemoryData: {
        EchartsData: {
          value: 0,
          MUse: 0,
          MTotal: 0,
          itemStyle: {
              color: "#C8A8E4",
          },
          detail: {
            formatter: (data) => {
              return (
                data +
                "%\n\n" +
                this.MemoryData.EchartsData.MUse +
                "G/" +
                this.MemoryData.EchartsData.MTotal +
                "G"
              );
            },
          },
        },
        GaugeID: "memory",
      },
      websocket: {},
      MyChart: {},
    };
  },
  created() {
    this.InitWebSocket();
  },
  mounted() {
    window.onbeforeunload = function () {
      this.closeWebSocket();
    };
  },
  methods: {
    // WebSocket初始化
    InitWebSocket: function () {
      const ServiceIp = window.apiURL.WebSocketIP;
      const UserName = "survey";
      const SocketServer = "survey";
      if ("WebSocket" in window) {
        this.websocket = new WebSocket(
          "wss://" + ServiceIp + "/" + SocketServer + "/" + UserName
        );
      } else if ("MozWebSocket" in window) {
        this.websocket = new WebSocket(
          "wss://" + ServiceIp + "/" + SocketServer + "/" + UserName
        );
      }
      /* else {}*/
      this.interval = window.setInterval(() => {
        //  每隔30秒钟发送一次心跳，避免websocket连接因超时而自动断开
        /*if (this.websocket != null) {
        }*/
      }, 30000);
      //  连接发生错误的回调方法
      this.websocket.onerror = function (/*ev*/) {};

      //  连接成功建立的回调方法
      this.websocket.onopen = function (/*ev*/) {};

      //  接收到消息的回调方法
      this.websocket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        this.MemoryData.EchartsData.MTotal = (data.mtotal / 1024).toFixed(2);
        this.MemoryData.EchartsData.MUse = (data.muse / 1024).toFixed(2);
        this.MemoryData.EchartsData.value = (
          (data.muse / data.mtotal) *
          100
        ).toFixed(0);
        console.log(this.MemoryData.EchartsData.MUse);
      };

      //  连接关闭的回调方法
      this.websocket.onclose = () => {
        this.websocket = null;
      };
    },
    //  WebSocket发送消息
    send(message) {
      if (this.websocket) {
        this.websocket.send(message);
      }
    },
    //  关闭WebSocket连接
    closeWebSocket() {
      if (this.websocket != null) {
        this.websocket.close();
      }
      if (this.interval) {
        window.clearInterval(this.interval);
      }
    },
  },
};
</script>

<style scoped>
.title {
  width: 100%;
  height: 100px;
  text-align: center;
}
.monitor {
  width: 100%;
  height: 500px;
  display: flex;
}
#memory {
  flex: 1;
}
#cpu {
  flex: 1;
}
</style>
