<template>
  <div class="tasks">
    <header>{{ taskinfo.taskname }}</header>
    <div class="task_info">
      <div class="task_info_box " v-if="taskinfo.subjectid != null">
        <label for="subject_name_input">科目名称：</label>
        <input id="subject_name_input" class="text_input" disabled="disable" type="text" v-model="taskinfo.subjectname" />
      </div>
      <div class="task_info_box">
        <label for="file_path_input">文件存储路径：</label>
        <input id="file_path_input" class="text_input" :disabled="disable" type="text" v-model="taskinfo.filepath" />
        <label for="file_name_input">文件存储名称：</label>
        <input id="file_name_input" class="text_input" :disabled="disable" type="text" v-model="taskinfo.filename" />
        <label for="task_deadline_input">任务截止时间：</label>
        <input id="task_deadline_input" class="text_input" :disabled="disable" type="datetime-local"
          v-model="taskinfo.taskdeadline" />
        <label for="active_box">任务状态：</label>
        <input id="active_box" :disabled="disable" type="checkbox" v-model="taskinfo.active" />
      </div>
      <div class="task_info_box">
        <label for="text_are">任务内容：</label>
        <textarea id="text_area" :disabled="disable" cols="50" rows="5" v-model="taskinfo.taskcontent"></textarea>
        <div id="task_button_box">
          <button v-show="disable" class="task_info_button" @click="disable=!disable">修改</button>
          <button v-show="!disable" class="task_info_button" @click="SaveTask">保存</button>
        </div>
      </div>
    </div>
    <div class="task_table">
      <table class="table">
        <thead>
          <tr>
            <th class="table_th" :key="index" v-for="(item, index) in table.tableHeader">{{item}}</th>
          </tr>
        </thead>
        <tbody class="table_tbody">
          <tr class="table_td" :key="index" v-for="(item, index) in table.tableData">
            <td>{{item.id}}</td>
            <td>{{item.filename}}</td>
            <td>{{item.user}}</td>
            <td>{{item.time}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "TasksItem",
  props: ['taskId'],
  data() {
    return {
      taskinfo: {
        taskname: "任务名称",
        taskcontent: "任务内容",
        filepath: "文件存储路径",
        filename: "文件存储名称",
        taskdeadline: "",
        active: true,
        subjectid: "1",
        subjectname: "科目名称",
      },
      disable: true,
      table: {
        tableHeader: ["序号", "文件名称", "提交者", "提交时间"],
        tableData: [
          { id: 1, filename: "文件名称", user: "提交者", time: "2022-01-01 00:00:00" },
          { id: 2, filename: "文件名称", user: "提交者", time: "2022-01-01 00:00:00" },
          { id: 3, filename: "文件名称", user: "提交者", time: "2022-01-01 00:00:00" },
          { id: 4, filename: "文件名称", user: "提交者", time: "2022-01-01 00:00:00" },
          { id: 5, filename: "文件名称", user: "提交者", time: "2022-01-01 00:00:00" },
          { id: 6, filename: "文件名称", user: "提交者", time: "2022-01-01 00:00:00" },
          { id: 7, filename: "文件名称", user: "提交者", time: "2022-01-01 00:00:00" },
          { id: 8, filename: "文件名称", user: "提交者", time: "2022-01-01 00:00:00" },
          { id: 9, filename: "文件名称", user: "提交者", time: "2022-01-01 00:00:00" },
          { id: 10, filename: "文件名称", user: "提交者", time: "2022-01-01 00:00:00" },
          { id: 1, filename: "文件名称", user: "提交者", time: "2022-01-01 00:00:00" },
          { id: 1, filename: "文件名称", user: "提交者", time: "2022-01-01 00:00:00" },
          { id: 1, filename: "文件名称", user: "提交者", time: "2022-01-01 00:00:00" },
          { id: 1, filename: "文件名称", user: "提交者", time: "2022-01-01 00:00:00" },
          { id: 1, filename: "文件名称", user: "提交者", time: "2022-01-01 00:00:00" },
          { id: 1, filename: "文件名称", user: "提交者", time: "2022-01-01 00:00:00" },
          { id: 1, filename: "文件名称", user: "提交者", time: "2022-01-01 00:00:00" },
          { id: 1, filename: "文件名称", user: "提交者", time: "2022-01-01 00:00:00" },
          { id: 1, filename: "文件名称", user: "提交者", time: "2022-01-01 00:00:00" },
          { id: 1, filename: "文件名称", user: "提交者", time: "2022-01-01 00:00:00" },
        ]
      }
    };
  },
  created() {
    this.GetTask();
  },
  mounted() { },
  methods: {
    GetTask: function () {
      var url = this.taskId == undefined ? this.$route.params.taskID : this.taskId
      this.axios
        .get("task/" + url)
        .then((response) => {
          this.taskinfo.taskname = response.data.taskName
          this.taskinfo.taskcontent = response.data.taskContent
          this.taskinfo.filepath = response.data.filepath
          this.taskinfo.filename = response.data.filename
          const data = new Date(response.data.taskDeadline).toISOString()
          this.taskinfo.taskdeadline = data.substring(0, data.length - 8)
          this.taskinfo.active = response.data.active == 1 ? true : false
          this.taskinfo.subjectid = response.data.subjectId
          this.taskinfo.subjectname = response.data.subjectName
        })
        .catch(function () {
        });
    },
    SaveTask: function () {
      var data = {
        taskName: this.taskinfo.taskname,
        taskContent: this.taskinfo.taskcontent,
        filepath: this.taskinfo.filepath,
        filename: this.taskinfo.filenamee,
        taskDeadline: this.taskinfo.taskdeadline,
        active: this.taskinfo.active,
      }
      this.axios
        .put("task/", data)
        .then((respone) => {
          if (respone.data) {
            alert("保存成功")
            this.disable = !this.disable
          }
          else {
            alert("保存失败")
          }

        })
        .catch(function () {
          alert("保存失败")
        })
    }
  },
  watch: {
    $route() {
      if (this.$route.params.taskID != undefined){
        this.GetTask();
      }
    },
    taskId() {
      this.GetTask();
    }
  },
};
</script>

<style scoped>
.tasks {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

header {
  font-size: 20px;
  flex: 1;
  text-align: center;
}

.task_info {
  flex: 7;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.task_info_box {
  display: flex;
  padding: 5px;
}

.text_input {
  border: 1px solid #99d3f5;
  font-size: 17px;
  margin-right: 10px;
}

#active_box {
  cursor: pointer;
}

#text_area {
  resize: none;
  font-size: 17px;
  border: 1px solid #99d3f5;
}

#task_button_box {
  align-self: center;
  margin-left: 50px;
  text-align: center
}

.task_info_button {
  border: 1px solid #99d3f5;
  background-color: #99d3f5;
  font-size: 17px;
  border-radius: 5px;
  cursor: pointer;
}

.task_table {
  flex: 15;
  display: flex;
}

.table {
  border: 1px solid #99d3f5;
  width: 100%;
  margin: 10px;
}

.table thead {
  height: 10%;
}

.table_th {
  color: #4f6b72;
  border: 1px solid #99d3f5;
}

.table_tbody {
  overflow-y: scoll;
  height: 90%;
}


.table_td td {
  text-align: center;
  border: 1px solid #99d3f5;
}
</style>