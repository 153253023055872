<template>
  <div class="background">
    <div class="head_box">
      {{ title }}
    </div>
    <div class="center_box">
      <div class="button_box">
        <div class="upload">
          <div id="select">
            <a class="file blue_border" href="javascript:"
              >选择文件
              <input
                type="file"
                style="cursor: pointer"
                multiple
                name="uploadFile"
              />
            </a>
          </div>
          <div id="upload">
            <a id="upload_text" class="file blue_border"
              >{{ rename === "" ? "上传" : "重命名并上传" }}
              <input
                style="cursor: pointer"
                type="button"
                value="上传"
                @click="PutFiles()"
              />
            </a>
          </div>
          <div id="rename">
            <input
              v-model="rename"
              class="blue_border rename"
              placeholder="上传前重命名"
              type="text"
            />
          </div>
        </div>
        <div class="progress">
          <div id="bar-warp">
            <div id="bar" />
          </div>
          <div id="progress-state">
            <div v-show="success === false" class="loading">
              <div />
              <div />
              <div />
              <div />
            </div>
            <div v-show="success" class="success">
              <div class="tick" />
              <!--            <svg width="400" height="400">-->
              <!--              <polyline fill="none" stroke="#68E534" stroke-width="24" points="88,214 173,284 304,138" stroke-linecap="round" stroke-linejoin="round" class="tick"/>-->
              <!--            </svg>-->
            </div>
          </div>
        </div>
      </div>
      <div class="tasks_box">
        <ul id="tasks_ul" ref="data">
          <li
            v-for="(task, taskIndex) in tasks"
            :key="taskIndex"
            class="li_list"
          >
            <span :class="{ span: task.finish }">{{ task.TaskName }}</span>
            <input v-model="task.finish" type="checkbox" name="checkbox" />
          </li>
        </ul>
      </div>
    </div>
    <div class="footer_box">
      <div v-show="record !== '' && icp !== ''" class="footer_bn">
        <a class="bn" target="_blank" href="https://www.beian.gov.cn/">
          <img
            src="../assets/images/record.png"
            class="recordImage"
            alt="备案"
          />
          <p class="url">{{ record }}</p></a
        >
        <a class="bn" target="_blank" href="https://beian.miit.gov.cn/"
          ><p class="url">{{ icp }}</p></a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { WebDAVClient } from "../assets/script/webdav";

export default {
  name: "UploadItem",
  data() {
    return {
      title: "班宝作业上传",
      record: "闽公网安备 35012102500217号",
      icp: "闽ICP备20000980号",
      success: false,
      url: "https://zuoye.0bug.cn:9443/dav/",
      username: "2156647945@qq.com",
      password: "lDihIpxzJlKZFYyHXlBCOdKzCMHZaixL",
      directory: "",
      rename: "",
      tasks: [],
    };
  },
  watch: {
    tasks: {
      handler() {
        this.SaveTask();
      },
      deep: true,
    },
  },
  created() {
    this.LoadTask();
    // this.SaveTask()
  },
  methods: {
    PutFiles: function () {
      const webDAVClient = new WebDAVClient(
        this.url,
        this.username,
        this.password
      );
      const files = document.querySelector('input[name="uploadFile"]').files;
      const length = files.length;
      let i, file;
      for (i = 0; i < length; i++) {
        file = files[i];
        let fileName = file.name;
        if (this.rename !== "") {
          let fileExtension = fileName.substring(fileName.lastIndexOf(".") + 1);
          if (
            fileExtension ===
            this.rename.substring(this.rename.lastIndexOf(".") + 1)
          ) {
            fileName = this.rename;
          } else {
            fileName = this.rename + "." + fileExtension;
          }
        }
        const Data = this.GetUploadPath(fileName);
        const FilePath = Data[0];
        const TaskID = Data[1];
        const time = Math.ceil(file.size / 524288) + 2;
        this.success = false;
        webDAVClient.put(
          this.directory + FilePath + "/" + fileName,
          () => {
            return this.PutCallback(TaskID);
          },
          file,
          time
        );
      }
    },
    PutCallback: function (TaskID) {
      for (const task of this.tasks) {
        if (task.id === TaskID) {
          task.finish = true;
        }
      }
      this.success = true;
      document.getElementById("bar").style.width = "100%";
    },
    LoadTask: function () {
      const that = this;
      let storageTasks = JSON.parse(localStorage.getItem("tasks"));
      if (storageTasks === null) {
        this.tasks = [];
      } else {
        this.tasks = storageTasks;
      }
      this.axios
        .get("task", { params: { active: 1 } })
        .then(function (response) {
          if (response.data.length !== 0) {
            let tasks = [];
            response.data.map((data) => {
              if (that.tasks.length === 0) {
                tasks.push({
                  id: data.id,
                  TaskName: data.taskName,
                  FilePath: data.filePath,
                  FileName: data.fileName,
                  finish: false,
                });
              } else {
                const index = that.tasks.findIndex(
                  (task) => task.id === data.id
                );
                if (index === -1) {
                  tasks.push({
                    id: data.id,
                    TaskName: data.taskName,
                    FilePath: data.filePath,
                    FileName: data.fileName,
                    finish: false,
                  });
                } else {
                  tasks.push({
                    id: data.id,
                    TaskName: data.taskName,
                    FilePath: data.filePath,
                    FileName: data.fileName,
                    finish: that.tasks[index].finish,
                  });
                }
              }
            });
            that.tasks = tasks;
          } else {
            that.tasks = [{ TaskName: "没有任务了", finish: true }];
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    SaveTask: function () {
      localStorage.setItem("tasks", JSON.stringify(this.tasks));
    },
    GetUploadPath: function (FileName) {
      if (this.tasks.length === 0) {
        return ["test", "0"];
      }
      for (const task of this.tasks) {
        if (FileName.lastIndexOf(task.FileName) !== -1) {
          return [task.FilePath, task.id];
        }
      }
      return ["test", "0"];
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/** {*/
/*  border: 1px solid black;*/
/*}*/
.background {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-image: url("https://tc.0bug.cn/0bug.cn/background.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.head_box {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4vh;
  font-weight: bold;
  background-color: rgba(255, 255, 224, 0.1);
}
.center_box {
  display: flex;
  flex-direction: column;
  flex: 19;
  width: 100%;
}
.footer_box {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 224, 0.1);
}
.button_box {
  display: flex;
  flex-direction: column;
  flex: 3;
  align-items: center;
}
.upload {
  flex: 3;
  display: block;
  box-sizing: border-box;
  padding-top: 2vh;
  padding-bottom: 2vh;
}
.progress {
  display: flex;
  flex: 1;
  position: relative;
  width: 25vw;
}
.file {
  position: relative;
  background: #d0eeff;
  overflow: hidden;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.file:hover {
  background: #aadffd;
  border-color: #78c3f3;
  color: #004974;
  text-decoration: none;
  cursor: pointer;
}
.file input {
  position: absolute;
  font-size: 10vmax;
  right: -1vw;
  top: -1vh;
  opacity: 0;
}
#select .file {
  text-decoration: none;
}
.rename {
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  text-align: center;
}
.rename::-webkit-input-placeholder {
  color: #1e88c7;
  font-size: 1.8vmin;
}
#bar-warp {
  flex: 19;
  box-sizing: border-box;
  height: 100%;
  border: 1px solid green;
  opacity: 0;
  transition: opacity 1s;
}
#bar {
  left: 1px;
  top: 1px;
  width: 0;
  height: 100%;
  background: green;
  transition: opacity 1s;
}
#progress-state {
  flex: 1;
  box-sizing: border-box;
  height: 100%;
  opacity: 0;

  transition: opacity 1s;
}
.loading {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.loading div {
  width: 6.7%;
  height: 25%;
  background-color: blue;
  display: inline-block;
  animation: load 0.5s cubic-bezier(0.77, 0.47, 0.64, 0.28) alternate infinite;
  -webkit-animation: load 0.5s cubic-bezier(0.77, 0.47, 0.64, 0.28) alternate
    infinite;
}
.loading div:nth-child(1) {
  animation-delay: -0.5s;
}
.loading div:nth-child(2) {
  animation-delay: -0.4s;
}
.loading div:nth-child(3) {
  animation-delay: -0.3s;
}
.loading div:nth-child(4) {
  animation-delay: -0.2s;
}
@keyframes load {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(3);
  }
}
@-webkit-keyframes load {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(3);
  }
}
.success {
  width: 100%;
  height: 100%;
}
.tasks_box {
  display: flex;
  flex: 7;
  justify-content: center;
  align-items: center;
}
#tasks_ul {
  max-width: 70%;
  min-width: 20%;
  border: 3px solid rgb(204, 204, 204);
  background-color: white;
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  margin-top: 5%;
  overflow-y: auto;
  opacity: 0.1;
  max-height: calc(25vh - 3px);
  transition: opacity 1s;
}
#tasks_ul:hover {
  opacity: 1;
}
#tasks_ul li {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 5vh;
  width: 100%;
  list-style: none;
  /*display: list-item;*/
  text-align: -webkit-match-parent;
  border-bottom: 3px solid rgb(204, 204, 204);
  overflow: hidden;
  margin: 0 auto;
  box-sizing: border-box;
}
#tasks_ul li span {
  flex: 19;
  text-align: left;
  margin-left: 5px;
  white-space: nowrap;
  display: inline-block;
  font-size: 2vh;
  overflow: auto;
}
#tasks_ul li span::-webkit-scrollbar {
  display: none;
}
#tasks_ul li input {
  flex: 1;
  justify-self: right;
  width: 2vh;
  height: 2vh;
  cursor: pointer;
}
#tasks_ul::-webkit-scrollbar {
  width: 10px;
  height: 1px;
}
#tasks_ul::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #535353;
}
#tasks_ul::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #ededed;
}
.url {
  color: lightgray;
  text-decoration: none;
  float: left;
  margin: 0 0 0 0.7vh;
}
.url:hover {
  color: #f00;
  cursor: hand;
}
h1 {
  text-align: center;
}
.footer_bn {
  text-align: center;
  font-size: 1.5vh;
  float: left;
  color: white;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.bn {
  text-decoration: none;
  height: 2.8vh;
  line-height: 2.8vh;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.recordImage {
  float: left;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
}
.tick {
  width: 50%;
  height: 21.43%;
  display: inline-block;
  border: solid green;
  border-width: 0 0 0.2vmax 0.2vmax;
  transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  vertical-align: baseline;
}
.span {
  text-decoration: line-through;
  color: lightgray;
}
@media (max-width: 600px) {
  .blue_border {
    width: 16vw;
  }
}
</style>
