<template>
    <div class="setting">
        <header>网站设置</header>
        <div class="setting_info">
            <div class="input_box" :key="index" v-for="(item, index) in setting">
                <label>{{ item.name }}：</label>
                <input class="text_input" :disabled="disable" type="text" v-model="item.value" />
            </div>
            <div id="setting_button_box">
                <button v-show="disable" class="setting_info_button" @click="disable=!disable">修改</button>
                <button v-show="!disable" class="setting_info_button" @click="SaveSetting">保存</button>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "SettingItem",
    data() {
        return {
            setting: {
                sitename: {
                    name: "网站名称",
                    value: "班宝作业上传"
                },
                webdavurl: {
                    name: "WebDav地址",
                    value: ""
                },
                webdavaccount: {
                    name: "WebDav账号",
                    value: ""
                },
                webdavpassword: {
                    name: "WebDav密码",
                    value: ""
                },
                sitebackimage: {
                    name: "网站背景图",
                    value: ""
                },
            },
            disable: true,
        };
    },
    created() {
    },
    mounted() {
        this.GetSetting();
    },
    methods: {
        GetSetting: function () {
            this.axios
                .get("setting")
                .then((response) => {
                    console.log(response);
                    console.log(this.setting);
                    this.setting.sitename.value = response.data.siteName
                    this.setting.webdavurl.value = response.data.webdavUrl
                    this.setting.webdavaccount.value = response.data.webdavAccount
                    this.setting.webdavpassword.value = response.data.webdavPassword
                    this.setting.sitebackimage.value = response.data.siteBackimage
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        SaveSetting: function () {
            var data={
                id: 1,
                siteName: this.setting.sitename.value,
                webdavUrl: this.setting.webdavurl.value,
                webdavAccount: this.setting.webdavaccount.value,
                webdavPassword: this.setting.webdavpassword.value,
                siteBackimage: this.setting.sitebackimage.value,
            }
            this.axios
                .put("setting", data)
                .then((response) => {
                    if (response.data){
                        alert("保存成功")
                        this.disable = !this.disable
                    }
                    else {
                        alert("保存失败")
                    }
                })
                .catch(function () {
                    alert("保存失败")
                })
        }
    },
    watch: {
    },
};
</script>
  
<style scoped>
header {
    font-size: 20px;
    text-align: center;
}

.setting_info {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.input_box {
    margin-bottom: 10px;
}

.text_input {
    border: 1px solid #99d3f5;
    font-size: 17px;
    margin-right: 10px;
}

.setting_info_button {
    border: 1px solid #99d3f5;
    background-color: #99d3f5;
    font-size: 17px;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 20px;
}
</style>